import React, { useState } from "react";

import { FeedbackTable } from "../components/FeedbackTable";
import useSWR from "swr";
import { useAuth0 } from "../react-auth0-spa";
import Loading from "../components/Loading";
import { RatingButton } from "../components/RatingButton";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const tableOptions = { selectableRows: "none" };

const fetcherWithToken = async (
  url: string,
  getToken: () => any,
  init?: RequestInit
) => {
  const tokenRaw = await getToken();
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${await tokenRaw.__raw}`,
      "X-Customer-Id": "covid-19",
    },
    credentials: "include",
    ...init,
  })
    .then((r) => r.json())
    .then((content) => content["data"]);
};

async function sendFeedback(
  signalId: string,
  feedbackType: string,
  getToken: () => any
) {
  const url = `${process.env.REACT_APP_SIGNALS_URL}/${signalId}/feedback`;
  const tokenRaw = await getToken();

  let headers = new Headers();
  headers.append("X-Customer-Id", "covid-19");
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${await tokenRaw.__raw}`);

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ feedback_type: feedbackType }),
  };

  return fetch(url, options);
}

export type feedbackButtonType = {
  id: string;
  status: boolean;
};

export default function () {
  const [feedbackButtons, setFeedbackbuttonsStatus] = useState<
    feedbackButtonType[]
  >([]);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [errors, setErrors] = useState<Object>({});
  const { getIdTokenClaims } = useAuth0();

  function formatResults(results: any[]) {
    return results.reduce((acc, curr, index: number) => {
      const { id, attributes } = curr;
      const { publication_date, filter_match, signal_properties } = attributes;
      acc.push({
        id,
        snippet: attributes.snippet,
        an: attributes.an,
        publication_date,
        fcode: filter_match.company.fcode,
        sig_properties: Object.keys(signal_properties)
          .map((k) => `${k.toUpperCase()}: ${signal_properties[k]}`)
          .join("\n\n"),
        signal_type: attributes.signal_type,
        template_name: attributes.template_name,
        feedback: (
          <RatingButton
            signalId={id}
            onFeedbackHandler={handleRating}
            feedbackButtons={feedbackButtons}
          />
        ),
      });

      return acc;
    }, []);
  }

  function handleRating(signalId: string, feedbackType: string) {
    if (feedbackType === "positive") {
      sendFeedback(
        signalId,
        "positive",
        async () => await getIdTokenClaims()
      ).then((response) => {
        if (response.ok) {
          setFeedbackbuttonsStatus([
            ...feedbackButtons,
            { id: signalId, status: true },
          ]);
        } else {
          setErrors({ status: response.status });
        }
      });
    } else {
      const mutatedData = data.filter(
        (item: { id: string }) => item.id !== signalId
      );
      mutate(mutatedData);
      sendFeedback(
        signalId,
        "negative_hide",
        async () => await getIdTokenClaims()
      ).then((response) => {
        if (response.ok) {
          setFeedbackbuttonsStatus([
            ...feedbackButtons,
            { id: signalId, status: false },
          ]);
        } else {
          setErrors({ status: response.status });
        }
      });
    }
  }

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const toIsoStringDate = (date: Date | null) => {
    return date?.toISOString().split("T")[0];
  };

  const signalsFullUrl = `${process.env.REACT_APP_SIGNALS_URL}?filterIds=${
    process.env.REACT_APP_COVID_FILTER
  }&dateBegin=${toIsoStringDate(startDate)}&dateEnd=${toIsoStringDate(
    endDate
  )}`;

  const { data, error, mutate } = useSWR(
    [signalsFullUrl, async () => await getIdTokenClaims()],
    fetcherWithToken
  );
  if (error) {
    return <div className="App">Failed to load!</div>;
  }
  return data ? (
    <div className="App">
      <div style={{ marginBottom: "1em" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            margin="normal"
            id="date-picker-inline"
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            margin="normal"
            id="date-picker-inline"
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <FeedbackTable
        title="Signals Feedback"
        columns={[
          "ID",
          "Snippet",
          "AN",
          "Publication Date",
          "Fcode",
          "Signal Properties",
          "Signal Type",
          "Template Name",
          "Feedback",
        ]}
        data={formatResults(data).map((item: object) => Object.values(item))}
        options={tableOptions}
      />
    </div>
  ) : (
    <div className="App">
      <Loading />
    </div>
  );
}
