import React from "react";
import MUIDataTable from "mui-datatables";

type TableProps = {
  title: string;
  columns: Array<string>;
  data: Array<Array<string>>;
  options: object;
};

export const FeedbackTable = ({
  title,
  columns,
  data,
  options,
}: TableProps) => {
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
};
