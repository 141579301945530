import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";

// styles
import "./App.css";

export default function () {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app">
        <NavBar />
        <div>
          <Switch>
            <PrivateRoute path="/" exact component={Home} />
            {/* <PrivateRoute path="/somePrivatePath" component={SomePrivateView} /> */}
          </Switch>
        </div>
      </div>
    </Router>
  );
}
