import React from "react";
import { feedbackButtonType } from "../views/Home";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { IconButton } from "@material-ui/core";

type RatingProps = {
  signalId: string;
  onFeedbackHandler: Function;
  feedbackButtons: feedbackButtonType[];
};

export const RatingButton = ({
  signalId,
  onFeedbackHandler,
  feedbackButtons,
}: RatingProps) => {
  const actualFeedbackButton = feedbackButtons.find(
    (button) => button.id === signalId
  );

  return (
    <div>
      <IconButton onClick={() => onFeedbackHandler(signalId, "positive")}>
        <ThumbUpIcon
          color={actualFeedbackButton?.status ? "primary" : undefined}
        />
      </IconButton>
      <IconButton onClick={() => onFeedbackHandler(signalId, "negative_hide")}>
        <ThumbDownIcon
          color={actualFeedbackButton?.status === false ? "error" : undefined}
        />
      </IconButton>
    </div>
  );
};
